<template>
  <div class="view view--user">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <h2>Informations</h2>
          <KeyValue label="Version" :value="currentVersion" />
          <KeyValue label="Version du navigateur" :value="userAgent" />
          <Btn text="Recharger l'application" color="primary" @click="refreshApp()" />
        </Container>
      </Section>
    </div>

  </div>
</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'AboutView',

  components: {
    KeyValue,
    Section,
    Container,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      resolution: '',
      userAgent: navigator.userAgent,
      currentVersion: null,
    }
  },

  mounted() {
    console.log('navigator', navigator)
    this.currentVersion = process.env.VERSION
  },

  methods: {
    refreshApp() {
      this.emitter.emit('open-loader')
      this.$store.dispatch('app/setReferentials').then(() => {
        this.$store.dispatch('app/setData').then(() => {
          this.emitter.emit('close-loader')
          window.location.reload(true)
        }, () => {
          this.emitter.emit('close-loader')
        })
      })
    },
  },
}
</script>
